import { loadingController } from '@ionic/core';

import { openIntercomMessenger } from './intercom';
import { AnalyticsEvent, trackAnalyticsEvent } from './analytics';
import { facebookTrackContact } from './facebook-tracking';

export async function openMessenger(channel: string, defaultMessage?: string) {
  trackAnalyticsEvent(AnalyticsEvent.messenger_opened, { channel });
  facebookTrackContact();
  const loading = await loadingController.create({});
  await loading.present();
  await openIntercomMessenger(channel, defaultMessage);
  loading.dismiss();
  // const presentingElement = getPresentingElement();
  // const modalOptions: ModalOptions = {
  //   presentingElement,
  //   canDismiss,
  //   component: 'og-conversation-page',
  //   backdropDismiss: false,
  //   componentProps: {
  //     conversation_id: groupState.groupDoc.group_id,
  //     defaultMessage,
  //     modal: modalController,
  //   },
  // };
  // const modal = await modalController.create(modalOptions);
  // return modal.present();
}
